<template>
  <div class="content dashboard-beneficiary">
    <div class="banner">
      <carousel :items-to-show="1"  :wrap-around="true" :autoplay="beneficiaryBanners > 1 ? 20000 : 0">
        <slide v-for="(entry, index) in beneficiaryBanners" :key="index">
          <!-- <template v-slot:img> -->
            <a :href="entry.link" target="_blank" @click="
              sendClickToGA(entry.category, entry.action, entry.label)
              ">
              <img :src="require('@/assets/images/banner/' + entry.fileName)" />
            </a>
          <!-- </template> -->
        </slide>

        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div v-if="currentAccount" class="tabs-container">
      <div class="tabs">
        <a
          v-for="(entry, index) in accounts"
          :key="index"
          @click="selectAccount(index)"
          :class="entry.product === currentAccount.product && 'active'"
        >
          <img
            :src="
              require('../../assets/images/logo-up-' + entry.product + '.png')
            "
          />
        </a>
      </div>
    </div>
    <div v-if="currentAccount" class="allstats">
      <div class="stats-transactions">
        <div class="arrow-left" @click="previousCard()">
          <img src="../../assets/images/arrow-left.svg" />
        </div>

        <div class="arrow-right" @click="nextCard()">
          <img src="../../assets/images/arrow-right.svg" />
        </div>
        <div class="transactions">
          <div class="bubblesa">
            <div
              v-for="(entry, index) in accounts"
              :key="index"
              @click="selectAccount(index)"
              class="onebubble"
              :class="entry.product === currentAccount.product && 'b-orange'"
            ></div>
          </div>
          <br />
          <br />
          <h3 class="c-balance">{{ $t("title.cardBalanceBubbleTitle") }}</h3>
          <div
            class="balance-circle"
            :class="'border-color-' + currentAccount.product"
          >
            <p class="mobile">
              <b>{{ getProductNameMethod(this.currentAccount.product) }}</b>
            </p>
            <p class="small white none desktop">
              {{ $t("title.balanceBubbleTitle") }}
            </p>
            <h1 class="white">
              {{ Math.floor(this.currentAccount.balance) }}
              <sup>
                ,{{ this.getFraction(this.currentAccount.balance)
                }}{{ $t("title.currency") }}
              </sup>
            </h1>
            <hr />
            <p class="small white">
              {{ this.surname }}
              <br />
              {{
                $t("title.spentAmountMessage", {
                  amount: this.formatAmount(this.currentAccount.spent)
                })
              }}
            </p>
          </div>
          <div class="offers-partners">
            <a
              :href="'/beneficiary/services?product=' + currentAccount.product"
            >
              <img src="../../assets/images/offers.svg" />
              {{ $t("title.offersButtonLabel") }}
            </a>
            <a
              href="https://upmoldova.md/harta-afiliati/"
              target="_blank"
            >
              <img src="../../assets/images/parteneri.svg" />
              {{ $t("title.partnersButtonLabel") }}
            </a>
            <a href="/beneficiary/services#recommendPartner">
              <img src="../../assets/images/recomanda.svg" />
              {{ $t("title.recommendPartnersButtonLabel") }}
            </a>
          </div>

          <div v-if="this.currentAccount.expire" class="expiring-funds-wrap">
            <div
              v-for="(entry, index) in this.currentAccount.expire"
              :key="index"
              class="expiring-funds"
            >
              <div align="left">
                <strong>{{ $t("title.expiringFundsTitle") }}</strong>
              </div>
              <div align="right">
                <strong>
                  {{ this.formatAmount(entry.amount) }}
                  {{ $t("title.currency") }}
                </strong>
                &nbsp;&nbsp;&nbsp;
                {{ getExpireAmountDateFormat(entry.date) }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="this.currentAccount.expire" class="expiring-funds-mobil">
          <div
            v-for="(entry, index) in this.currentAccount.expire"
            :key="index"
          >
            <div align="left">
              <strong>{{ $t("title.expiringFundsTitle") }}</strong>
            </div>
            <div align="right">
              <strong>
                {{ this.formatAmount(entry.amount) }}
                {{ $t("title.currency") }}
              </strong>
              &nbsp;&nbsp;&nbsp;
              {{ getExpireAmountDateFormat(entry.date) }}
            </div>
          </div>
        </div>
        <div class="expiring-funds-mobil" v-else>&nbsp;&nbsp;&nbsp;</div>
        <!-- <carousel :items-to-show="1"  :wrap-around="true" :autoplay="2000">
            <slide v-for="(entry, index) in beneficiaryBanners" :key="index">
                <a :href="entry.link" target="_blank" @click="
                  sendClickToGA(entry.category, entry.action, entry.label)
                  ">
                  <img :src="require('@/assets/images/banner/' + entry.fileName)" />
                </a>
            </slide>

            <template #addons>
              <navigation />
            </template>
          </carousel> -->
        <div class="grafic">
          <div class="filter">
            <date-range @close="dataChanged" :time="this.time" />
          </div>
          <h3 class="tstats">{{ $t("title.transactionStatsTitle") }}</h3>
          <div class="graphic" >
            <transactions-chart :chart-data="this.transactionsChartData" />
          </div>
        </div>
      </div>
      <br />
      <h3 class="tstats">
        <span class="transaction-title">{{ $t("title.transactionsListTitle") }}</span>
        <download-excel
          :data="this.transactions"
          type="csv"
          :fields="this.excelFields"
          class="download-wrapper"
        >
          <img class="download" src="../../assets/images/download.svg" />
        </download-excel>
      </h3>

      <div v-if="this.transactionsCount === 0" class="tstats">
        <p>{{ $t("title.transactionNoTransactionsMessage") }}</p>
      </div>
      <div v-else>
        <div
          v-for="(transactionsWeek, index) in transactionsByWeek"
          :key="index"
        >
          <div v-if="transactionsByWeek[index]">
            <div v-if="index === 0" class="period">
              {{ $t("title.transactionListPeriodThisWeek") }}
            </div>
            <div v-else class="period">
              {{ $t("title.transactionListPeriodWeek", { index: index }) }}
            </div>
            <div
              v-for="transaction in transactionsByWeek[index]"
              :key="transaction.id"
              class="transaction"
            >
              <div>
                <img :src="getTransactionImage(transaction)" />
              </div>
              <div v-if="transaction.affiliate">
                <div class="transaction-name">
                  {{ transaction.affiliate.name }}
                </div>
                <p
                  v-if="transaction.affiliate.address"
                  class="transaction-address"
                >
                  {{ transaction.affiliate.address.street_name }},
                  {{ transaction.affiliate.address.locality }},
                  {{ transaction.affiliate.address.county }}
                </p>
                <p class="transaction-date">
                  <small>
                    {{ getTransactionAmountDateFormat(transaction.date) }}
                  </small>
                </p>
                <p
                  v-if="transaction.transaction_status === 'REJECTED'"
                  class="transaction-rejected-reason"
                >
                  {{ transaction.reason }}
                </p>
              </div>

              <div>
                <div
                  class="amount-bold"
                  :class="getTransactionAmountClass(transaction)"
                >
                  {{ transaction.signedAmount }} {{ $t("title.currency") }}
                </div>
                <div
                  v-if="
                    transaction.transaction_status === 'REJECTED' &&
                    transaction.transaction_type === 'DEBIT'
                  "
                  class="rejected"
                >
                  REJECTED
                </div>
                <div
                  v-else-if="
                    transaction.transaction_status === 'REJECTED' &&
                    transaction.transaction_type === 'CREDIT'
                  "
                  class="rejected-credit"
                >
                  CANCELED
                </div>
                <div v-else>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div id="modal_popup_welcome2" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_welcome2', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-html="$t('title.beneficiaryPopupMessagePhishing')" class="text-justify"></p>
        <a
          class="button-orange"
          @click="showOrHideFirstPopup('modal_popup_welcome2')"
          >{{ $t("title.nextStepButtonLabel") }}</a
        >
      </div>
    </div> -->
    <div id="modal_popup_error_loading" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_error_loading', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <!-- <p v-html="$t('title.errorMultibenMaintenanceBeneficiary', {name: this.surname}) "></p> -->
        <a
          class="button-orange"
          @click="showModalPopup('modal_popup_error_loading', false)"
          >{{ $t("title.nextStepButtonLabel") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import utils from "@/utils.js";
import httpServiceBeneficiary from "../../http/http-beneficiary.js";

import CardReincarcareSVG from "../../assets/images/card-reincarare.svg";
import CardTranzactieSVG from "../../assets/images/card-tranzactie.svg";
import CardReincarcareProcramSVG from "../../assets/images/card-tranzactie-procram.svg";
import TransactionsChart from "../../components/beneficiary/TransactionChart.vue";
import DateRange from "../../components/DateRange.vue";
import Cookies from "js-cookie";
import JsonExcel from "vue-json-excel3";
import { Carousel, Slide,  Navigation } from 'vue3-carousel'
import beneficiaryBanners from "@/assets/json/beneficiary_banner.js";

export default {
  setup() {
    const { getProductName, formatAmount } = utils();

    return {
      getProductName,
      formatAmount
    }
  },
  components: {
    TransactionsChart,
    DateRange,
    "download-excel": JsonExcel,
    Carousel,
    Slide,
    Navigation
  },
  props: {
    accounts: Array,
    surname: String,
    accessToken: String,
    msg: String
  },
  data() {
    return {
      time: [moment().subtract(3, "month"), moment()],
      currentAccount: null,
      transactions: null,
      dashboardError: null,
      transactionsCount: 0,
      transactionsChartData: null,
      selectedCardIndex: 0,
      excelFields: {
        Id: "transaction_id",
        Magazin: "affiliate.name",
        Data: {
          field: "date",
          callback: (value) => {
            return moment(new Date(value), "YYYY-MM-DDThh:mm:ss").format(
              "DD/MM/YYYY"
            );
          }
        },
        Ora: {
          field: "date",
          callback: (value) => {
            return moment(new Date(value), "YYYY-MM-DDThh:mm:ss").format(
              "hh:mm"
            );
          }
        },
        Tip: "transaction_type",
        Status: "transaction_status",
        Amount: "signedAmount",
      },
      beneficiaryBanners: beneficiaryBanners,
    };
  },
  methods: {
    getProductNameMethod(productId) {
      return this.getProductName(productId);
    },
    getExpireAmountDateFormat(date) {
      return moment(new Date(date), "YYYY-MM-DD").format("DD MMM YYYY");
    },
    getTransactionAmountDateFormat(date) {
      return moment(new Date(date), "YYYY-MM-DDTHH:mm:ss").format(
        "DD.MM.YYYY HH:mm"
      );
    },
    selectAccount(index) {
      if (this.accounts[index]) {
        this.currentAccount = this.accounts[index];
        if (this.currentAccount.product === 11) {
          this.time = [moment().subtract(3, "month"), moment()];
        } else {
          this.time = [moment().subtract(1, "year"), moment()];
        }
        this.searchTransacions();
      }
    },
    fillChartData() {
      if (this.transactions != null) {
        let chartDataTemp = [];
        let index = this.transactions.length - 1;
        while (index >= 0) {
          this.transactions[index].signedAmount =
            this.getTransactionAmountSign(this.transactions[index]) +
            "" +
            this.formatAmount(this.transactions[index].amount);
          if (
            this.transactions[index].transaction_status === "ACCEPTED" &&
            this.transactions[index].transaction_type === "DEBIT"
          ) {
            chartDataTemp.push({
              date: this.getExpireAmountDateFormat(
                this.transactions[index].date
              ),
              expense: this.transactions[index].amount,
              sold: this.transactions[index].balance
            });
          }
          index--;
        }

        let dateTransactions = chartDataTemp.map((a) => a.date);
        this.transactionsChartData = {
          labels: dateTransactions,
          datasets: [
            {
              label: this.$t("title.expenseText"),
              backgroundColor: "#c61b10",
              borderColor: "#c61b10",
              data: chartDataTemp.map((a) => a.expense),
              fill: false,
              pointRadius: 0,
            },
            {
              label: this.$t("title.soldText"),
              backgroundColor: "#98CB00",
              borderColor: "#98CB00",
              data: chartDataTemp.map((a) => a.sold),
              fill: false,
              pointRadius: 0
            }
          ]
        };
      }
    },
    getExpenseFromTransaction(transaction) {
      let expense = null;
      if (transaction.transaction_type === "CREDIT") {
        expense = 0;
      } else {
        expense = transaction.amount;
      }
      return expense;
    },
    getIncomeFromTransaction(transaction) {
      let income = null;
      if (transaction.transaction_type === "CREDIT") {
        income = transaction.amount;
      } else {
        income = 0;
      }
      return income;
    },
    getTransactionImage(transaction) {
      let imgsrc = null;
      if (transaction.transaction_type === "CREDIT") {
        imgsrc = CardReincarcareSVG;
      } else {
        if (transaction.transaction_status === "REJECTED") {
          imgsrc = CardTranzactieSVG;
        } else if (transaction.transaction_status === "ACCEPTED") {
          imgsrc = CardTranzactieSVG;
        } else if (transaction.transaction_status === "UNLOADED") {
          // unloaded
          imgsrc = CardReincarcareProcramSVG;
        } else {
          imgsrc = CardTranzactieSVG;
        }
      }
      return imgsrc;
    },
    getTransactionAmountClass(transaction) {
      if (transaction.transaction_type === "CREDIT") {
        if (transaction.transaction_status === "REJECTED") {
          return "green-rejected";
        } else if (transaction.transaction_status === "ACCEPTED") {
          return "green";
        } else {
          return "";
        }
      } else {
        if (transaction.transaction_status === "REJECTED") {
          return "red";
        } else if (transaction.transaction_status === "ACCEPTED") {
          return "";
        } else if (transaction.transaction_status === "UNLOADED") {
          // unloaded
          return "";
        } else {
          return "";
        }
      }
    },
    getTransactionAmountSign(transaction) {
      if (transaction.transaction_type === "CREDIT") {
        return "+";
      } else {
        if (transaction.transaction_status === "REJECTED") {
          return "-";
        } else if (transaction.transaction_status === "ACCEPTED") {
          return "-";
        } else if (transaction.transaction_status === "UNLOADED") {
          // unloaded
          return "-";
        } else {
          return "-";
        }
      }
    },
    previousCard() {
      var noOfCards = this.accounts.length;
      this.selectedCardIndex--;
      if (this.selectedCardIndex < 0) {
        this.selectedCardIndex = noOfCards - 1;
      }
      this.currentAccount = this.accounts[this.selectedCardIndex];
    },
    nextCard() {
      var noOfCards = this.accounts.length;
      this.selectedCardIndex++;
      if (this.selectedCardIndex === noOfCards) {
        this.selectedCardIndex = 0;
      }
      this.currentAccount = this.accounts[this.selectedCardIndex];
    },
    getFraction(amount) {
      amount = ((amount - Math.floor(amount)).toFixed(2) * 100).toFixed(0);
      if (amount > 9) {
        return amount;
      } else {
        return "0" + amount;
      }
    },
    dataChanged(value) {
      this.time = value;
      this.searchTransacions();
    },
    searchTransacions() {
      var formData =
        "?account_id=" +
        this.currentAccount.id +
        "&start_date=" +
        moment(this.time[0]).format("YYYYMMDD") +
        "&end_date=" +
        moment(this.time[1]).format("YYYYMMDD") +
        "&lang=" +
        localStorage.language +
        "&page=1" +
        "&per_page=10000";

      httpServiceBeneficiary.beneficiaryService
        .searchTransactionsForAccount(formData, this.accessToken)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {})
        .then((response) => {
          if (response.status == 200) {
            this.transactionsCount = Number(response.headers["X-Up-Count"]);
            this.transactions = response.data;
            if (this.transactions.length === 0) {
              this.transactionsCount = 0;
            }
            this.fillChartData();
          } else if (response.status == 400 || response.status == 403) {
            this.dashboardError = {
              code: response.data.error_code,
              description: this.getErrorMessage(response.data.error_code)
            };
            this.transactionsCount = 0;
          } else {
            this.dashboardError = { description: response.description };
            this.transactionsCount = 0;
          }
        });
    },
    getErrorMessage(code) {
      let config = JSON.parse(sessionStorage.getItem("mobile_jsonFile"));
      return config.errors[code];
    },
    sendClickToGA(category, action, label) {
      if (this.$ga) {
        this.$ga.event({
          eventCategory: category,
          eventAction: action,
          eventLabel: label
        });
      }
    },
    showOrHideFirstPopup(modal_id) {
      Cookies.set("firstBeneficiary", true);
      this.showModalPopup(modal_id, false);
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    }
  },
  computed: {
    getProductName(productId) {
      return this.getProductName(productId);
    },
    getTabClass(accountId) {
      return accountId === this.currentAccount.id && "active";
    },
    transactionsByWeek() {
      let transactionsByWeek = new Array();
      var currentDate = new moment();
      for (var index in this.transactions) {
        var transDate = moment(
          new Date(this.transactions[index].date),
          "YYYY-MM-DDTHH:mm:ss"
        );
        var weekIndex = Math.floor(currentDate.diff(transDate, "days") / 7);
        if (!transactionsByWeek[weekIndex]) {
          transactionsByWeek[weekIndex] = new Array();
        }
        transactionsByWeek[weekIndex].push(this.transactions[index]);
      }
      return transactionsByWeek;
    }
  },
  created() {
    this.selectAccount(0);
  },
  mounted() {
    // if (!Cookies.get("firstBeneficiary")) {
    //   this.showModalPopup("modal_popup_welcome2", true);
    // }
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style lang="scss" src="@/assets/styles/webchat-style.scss"></style>

<style scoped>
.amount-bold {
  font-weight: bold;
  text-align: right;
  font-size: 16px;
  padding-right: 15px;
  float: right;
}

.transactions {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  position: relative;
}
.transaction p {
  line-height: 15px;
}
.transaction {
  display: grid;
  padding-left: 40px;
  grid-template-columns: 58px auto 190px;
  align-content: center;
  align-items: center;
  height: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d8d8d8;
  text-align: left;
}

.rejected {
  width: 70px;
  border-radius: 20px;
  font-size: 12px;
  border: 2px solid #cc0000;
  color: #cc0000;
  text-align: center;
  float: left;
}

.rejected-credit {
  width: 70px;
  border-radius: 20px;
  font-size: 12px;
  border: 2px solid #0ac694;
  color: #0ac694;
  text-align: center;
  float: left;
}
.expiring-funds-wrap {
  margin-top: 40px;
  /* position: absolute; */
  max-height: 100px;
  /* left: 0;
  bottom: 0; */
  width: 100%;
}

.green-rejected {
  color: #0ac694;
  text-decoration: line-through;
}

.transaction-name {
  font-size: 16px;
  font-weight: bold;
  margin: 5;
}

.transaction-address {
  font-size: 12px;
  font-style: italic;
  margin: 0;
  color: #9b9a9a;
}

.transaction-date {
  font-size: 16px;
}

.transaction-rejected-reason {
  font-size: 12px;
  font-style: italic;
  margin: 0;
  color: red;
}

.expiring-funds {
  width: 100%;
  height: 50px;
  background: #d8d8d8;
  display: grid;
  grid-template-columns: auto auto;
  align-content: center;
}

.expiring-funds > div {
  padding: 0 10px;
}

.expiring-funds-mobil {
  width: 100%;
  height: 50px;
  background: #d8d8d8;
  grid-template-columns: auto auto;
  margin-top: 40px;
  align-content: center;
  display: none;
}
.stats-transactions {
  display: grid;
  grid-template-columns: 420px auto;
  padding-bottom: 10px;
  width: 100%;
  margin: 0px auto;
}

.stats-transactions div:first-child h3 {
  padding-left: 40px;
}

.offers-partners {
  width: 64.5% !important;
  margin: 10px auto;
}

.offers-partners a {
  display: grid;
  width: 76px;
  height: 76px;
  border-radius: 76px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.52);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.52);
  float: left;
  margin-right: 10px;
  text-decoration: none;
  text-align: center;
  font-size: 10px;
  color: #979797;
  align-items: center;
  align-content: center;
  line-height: 10px;
  cursor: pointer;
}

.offers-partners a img {
  display: block;
  margin: 0px auto 5px;
}

.tstats {
  margin-top: 5px !important;
  padding-left: 40px;
  text-align: left;
}

.graphic {
  width: 80%;
  display: block;
  margin: 40px auto;
  min-height: 250px !important;
}

.period {
  height: 26px;
  background: #f1f1f1;
  padding-left: 40px;
  font-size: 12px;
  padding-top: 10px;
  text-align: left;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .transaction {
    display: block;
    padding-left: 40px;
    align-content: center;
    align-items: center;
    height: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d8d8d8;
  }
  .transaction > div {
    float: left;
  }
  .transaction > div:nth-child(1) {
    width: 58px;
    padding-top: 10px;
  }
  .transaction > div:nth-child(2) {
    width: auto;
  }
  .transaction > div:nth-child(3) {
    width: 110px;
    float: right;
  }
  .transaction > div:nth-child(4) {
    width: 100px;
    float: right;
    padding-top: none;
  }
  .rejected {
    width: 80px;
    height: 19px;
    border-radius: 20px;
    font-size: 12px;
    border: 2px solid #cc0000;
    color: #cc0000;
    text-align: center;
    padding-top: 3px !important;
  }
  .rejected-credit {
    width: 80px;
    height: 19px;
    border-radius: 20px;
    font-size: 12px;
    border: 2px solid #0ac694;
    color: #0ac694;
    text-align: center;
    padding-top: 3px !important;
  }
  .transaction p {
    line-height: 15px;
  }
  .tstats {
    margin-top: 5px !important;
    padding-left: 40px;
  }
  .period {
    height: 26px;
    background: #f1f1f1;
    padding-left: 40px;
    font-size: 12px;
    padding-top: 10px;
  }
  .expiring-funds {
    width: 100%;
    height: 50px;
    background: #d8d8d8;
    display: block;
    grid-template-columns: auto auto;
    margin-top: 40px;
    align-content: center;
  }

  .expiring-funds div {
    text-align: center;
  }
  .expiring-funds > div:nth-child(1) {
    text-align: left;
    float: left;
    width: auto;
    padding-top: 15px;
  }
  .expiring-funds > div:nth-child(2) {
    text-align: right;
    float: right;
    width: auto;
    padding-top: 15px;
  }

  .expiring-funds-mobil {
    width: 100%;
    height: 50px;
    background: #d8d8d8;
    grid-template-columns: auto auto;
    margin-top: 40px;
    align-content: center;
    display: none;
  }
  .transactions {
    -ms-grid-column: 1;
    grid-column: 1;
  }
  .transactions .offers-partners a {
    display: block;
    width: 76px;
    height: 56px;
    padding-top: 20px;
    border-radius: 76px;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.52);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.52);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.52);
    float: left;
    margin-right: 10px;
    text-decoration: none;
    text-align: center;
    font-size: 10px;
    color: #979797;
    align-items: center;
    align-content: center;
    line-height: 10px;
  }
  .transactions {
    border-right: 1px solid #ddd;
  }
  .stats-transactions {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 420px calc(100% - 420px);
    grid-template-columns: 420px calc(100% - 420px);
  }
  .stats-transactions div:first-child h3 {
    padding-left: 40px;
  }
  .offers-partners {
    width: 64.5% !important;
    margin: 10px auto;
  }
  .offers-partners a img {
    display: block;
    margin: 0px auto 2px;
  }
}
@media (max-width: 1200px) {
  .offers-partners {
    min-height: 76px;
    width: 33%;
  }
  .expiring-funds-wrap {
    position: relative;
  }
  .stats-transactions {
    display: grid;
    grid-template-columns: 100%;
    padding-bottom: 10px;
    width: 100%;
    margin: 0px auto;
  }
}
@media (max-width: 1200px) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .offers-partners {
    width: 64.5% !important;
  }
  .stats-transactions {
    display: grid;
    grid-template-columns: 100%;
    padding-bottom: 10px;
    width: 100%;
    margin: 0px auto;
  }
}
@media (max-width: 1096px) {
  .offers-partners {
    width: 36% !important;
  }
}
@media (max-width: 1096px) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .transactions .offers-partners {
    width: 64.5% !important;
  }
}
@media (max-width: 1024px) {
  .offers-partners {
    width: 38% !important;
  }
}
@media (max-width: 1024px) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .offers-partners {
    width: 64.5% !important;
  }
}
@media (max-width: 1003px) {
  .expiring-funds {
    display: none;
  }
  .expiring-funds-mobil {
    width: 100%;
    height: 50px;
    background: #d8d8d8;
    margin-top: 50px;
    align-content: center;
    display: block;
  }
  .expiring-funds-mobil > div {
    width: 100%;
    align-content: center;
    display: grid;
    height: 50px;
    grid-template-columns: auto auto;
  }
  .expiring-funds-mobil > div > div {
    padding-left: 10px;
    padding-right: 10px;
  }
  .transactions {
    border: 0 none;
  }
  .transactions br {
    display: none;
  }

  .transaction {
    padding: 10px;
    grid-template-columns: 40px auto 76px;
    height: auto;
  }
  .transaction img {
    width: 30px;
  }
  .transaction-name {
    font-size: 14px;
  }
  .transaction-date {
    margin: 0;
  }
  .amount-bold {
    font-size: 12px;
    float: right;
  }

  .stats-transactions {
    position: relative;
  }
  .stats-transactions div.transactions {
    background: url(../../assets/images/bg-tranzactii.png) no-repeat center
      bottom;
    background-size: cover;
    margin: 0px auto;
    width: 100%;
    position: relative;
    min-height: 400px;
  }

  .offers-partners {
    width: 100% !important;
    margin: 10px auto;
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    align-content: center;
    position: absolute;
    bottom: -50px;
  }
  .offers-partners a {
    display: grid;
    width: 106px;
    height: 106px;
    border-radius: 76px;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.52);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.52);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.52);
    float: none !important;
    margin-right: 10px;
    text-decoration: none;
    text-align: center;
    font-size: 10px;
    color: #979797;
    align-items: center;
    align-content: center;
    line-height: 10px;
    margin: 0px auto;
    background: #fff !important;
  }
}
@media (max-width: 1003px) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .expiring-funds-mobil {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 2;
    grid-row: 2;
  }
  .transactions {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
  }
  .transactions .offers-partners {
    width: auto !important;
  }
  .stats-transactions {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
  }
  .offers-partners {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto auto auto;
    grid-template-columns: auto auto auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .offers-partners > a:nth-child(1) {
    -ms-grid-column: 1;
    grid-column: 1;
  }
  .offers-partners > a:nth-child(2) {
    -ms-grid-column: 2;
    grid-column: 2;
  }
  .offers-partners > a:nth-child(3) {
    -ms-grid-column: 3;
    grid-column: 3;
  }
  .expiring-funds-mobil > div > div:nth-child(1) {
    text-align: left;
    float: left;
    width: auto;
    padding-top: 15px;
  }
  .expiring-funds-mobil > div > div:nth-child(2) {
    text-align: right;
    float: right;
    width: auto;
    padding-top: 15px;
  }
}
@media (max-width: 400px) {
  .offers-partners a {
    width: 90px;
    height: 90px;
  }
}
</style>
