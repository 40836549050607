<template>
  <div v-if="accounts" class="middle-placed-content">
    <div class="cards-container">
      <div class="cards-listing">
        <h3>{{ $t("title.cardsPageTitle") }}</h3>
        <div>
          <a
            v-for="(entry, ind) in accounts"
            :key="ind"
            class="nav-item border-left carditem"
            :class="[
              'border-left-color-' + entry.product,
              entry.id === currentAccount.id
                ? 'card-active'
                : 'card-inactive'
            ]"
            @click="selectAccount(ind)"
          >
            <div class="logo-card">
              <img
                :src="
                  getImagePath(entry.product)
                "
              />
            </div>
            <div class="sum">{{ this.formatAmount(entry.balance) }} lei</div>
            <br />
            <br />
            <div class="validity">
              {{ $t("title.cardValidLabel") }} {{ entry.card.expire }}
            </div>
          </a>
        </div>
      </div>
      <div class="cards-details view">
        <div :class="[!cardSuccess ? 'show' : 'hide']">
          <card-template
            :amountLeft="this.formatAmount(this.currentAccount.balance)"
            :cardPan="this.getFormattedPan(this.currentAccount.card.pan)"
            :embossName="this.currentAccount.card.name"
            :expireLabel="this.currentAccount.card.expire"
            :product="this.currentAccount.product"
          ></card-template>
          <table cellpadding="10" cellspacing="0" align="center">
            <tbody>
              <tr>
                <td
                  align="center"
                  valignn="top"
                  v-if="this.currentAccount.card.state === 'created'"
                >
                  <a
                    href="#"
                    @click="
                      showModalPopup('modal_popup_confirmActivateCard', true)
                    "
                  >
                    <img src="../../assets/images/activate-card.svg" />
                    <span v-html="$t('title.activeCardText')"></span>
                  </a>
                </td>
                <td
                  align="center"
                  valignn="top"
                  v-if="this.currentAccount.card.state === 'active'"
                >
                  <a
                    href="#"
                    @click="
                      showModalPopup('modal_popup_confirmBlockCard', true)
                    "
                  >
                    <img src="../../assets/images/card-locked.svg" />
                    <span v-html="$t('title.blockCardText')"></span>
                  </a>
                </td>
                <td
                  align="center"
                  valignn="top"
                  v-if="this.currentAccount.card.state === 'blocked'"
                >
                  <a
                    href="#"
                    @click="
                      showModalPopup('modal_popup_confirmUnblockCard', true)
                    "
                  >
                    <img src="../../assets/images/activate-card.svg" />
                    <span v-html="$t('title.unblockCardText')"></span>
                  </a>
                </td>
                <!-- <td
                  align="center"
                  valign="top"
                  v-if="
                    this.currentAccount.card.state === 'cardblocked' ||
                      this.currentAccount.card.state === 'blocked' ||
                      this.currentAccount.card.state === 'deactivated' ||
                      this.currentAccount.card.state === 'pinblocked'
                  "
                >
                  <a
                    href="#"
                    @click="
                      showModalPopup('modal_popup_confirmCardRequest', true)
                    "
                  >
                    <img src="../../assets/images/request-pin.svg" />
                    <span v-html="$t('title.requestCardText')"></span>
                  </a>
                </td> -->
                <td align="center" valign="top">
                  <a href="/beneficiary/faq">
                    <img src="../../assets/images/faq-big.svg" />
                    <span v-html="$t('title.faqText')"></span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <p style="padding: 0 10px; width: 70%; margin: 0 auto;">
            <a href="/beneficiary/services?download=1">{{
              $t("title.showPinText")
            }}</a>
          </p>
        </div>

        <div :class="[cardSuccess ? 'show' : 'hide']">
          <img src="../../assets/images/succes-pin.svg" />
          <p align="center">
            <small>
              <span v-if="cardSuccess">
                {{ $t("title.successMsg", { PIN: "CARD" }) }}
              </span>
            </small>
          </p>
        </div>
      </div>
    </div>

    <!-- <div id="modal_popup_pinOrCardRequestError" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_pinOrCardRequestError', false)"
          class="close-button"
        >
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('title.errorPinOrCardReqMsg')">
        </p>
      </div>
    </div> -->

    <div id="modal_popup_confirmActivateCard" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_confirmActivateCard', false)"
          class="close-button"
        >
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('title.confirmActvatedCardReqMsg')">
        </p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="activateOrBlockCard(true)"
            >Yes</a
          >
          <a
            href="#"
            class="noButton"
            @click="showModalPopup('modal_popup_confirmActivateCard', false)"
            >No</a
          >
        </div>
      </div>
    </div>

    <div id="modal_popup_confirmBlockCard" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_confirmBlockCard', false)"
          class="close-button"
        >
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('title.confirmBlockCardReqMsg')">
        </p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="activateOrBlockCard(false)"
            >Yes</a
          >
          <a
            href="#"
            class="noButton"
            @click="showModalPopup('modal_popup_confirmBlockCard', false)"
            >No</a
          >
        </div>
      </div>
    </div>

    <div id="modal_popup_confirmUnblockCard" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_confirmUnblockCard', false)"
          class="close-button"
        >
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('title.confirmUnblockCardReqMsg')">
        </p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="activateOrBlockCard(true)"
            >Yes</a
          >
          <a
            href="#"
            class="noButton"
            @click="showModalPopup('modal_popup_confirmUnblockCard', false)"
            >No</a
          >
        </div>
      </div>
    </div>

    <!-- <div id="modal_popup_confirmCardRequest" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_confirmCardRequest', false)"
          class="close-button"
        >
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('title.confirmCardReqMsg')"></p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="reissueRequest('RC')">Yes</a>
          <a
            href="#"
            class="noButton"
            @click="showModalPopup('modal_popup_confirmCardRequest', false)"
            >No</a
          >
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import utils from "@/utils.js";
import CardTemplate from "../../assets/components/CardTemplate.vue";
import httpServiceBeneficiary from "../../http/http-beneficiary.js";

export default {
  setup() {
    const { getProductName, formatPAN, formatAmount } = utils();

    return {
      getProductName,
      formatPAN,
      formatAmount
    }
  },
  components: {
    CardTemplate
  },
  props: {
    accounts: Array,
    surname: String,
    accessToken: String,
    msg: String
  },
  data() {
    return {
      currentAccount: this.accounts[0],
      transactions: null,
      dashboardError: null,
      transactionsCount: 0,
      selectedCardIndex: 0,
      cardSuccess: false,
      cardRequestMessage: false
    };
  },
  methods: {
    getProductNameMethod(productId) {
      return this.getProductName(productId);
    },
    getFormattedPan(pan) {
      return this.formatPAN(pan);
    },
    selectAccount(index) {
      this.currentAccount = this.accounts[index];
      this.cardSuccess = false;
    },
    previousCard() {
      var noOfCards = this.accounts.length;
      this.selectedCardIndex--;
      if (this.selectedCardIndex < 0) {
        this.selectedCardIndex = noOfCards - 1;
      }
      this.currentAccount = this.accounts[this.selectedCardIndex];
    },
    nextCard() {
      var noOfCards = this.accounts.length;
      this.selectedCardIndex++;
      if (this.selectedCardIndex === noOfCards) {
        this.selectedCardIndex = 0;
      }
      this.currentAccount = this.accounts[this.selectedCardIndex];
    },
    getImagePath(product){
      return require('../../assets/images/logo-up-' + product + '.png');
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    activateOrBlockCard(cardStatus) {
      let formData = {
        id: this.currentAccount.card.id,
        active: cardStatus
      };
      httpServiceBeneficiary.beneficiaryService
        .setStateOrReasonCard(formData, this.accessToken)
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status === 200) {
            this.currentAccount.card.active = cardStatus;
            this.currentAccount.card.state = response.data.state;
            this.showModalPopup("modal_popup_confirmActivateCard", false);
            this.showModalPopup("modal_popup_confirmBlockCard", false);
            this.showModalPopup("modal_popup_confirmUnblockCard", false);
          }
        })
        .catch(function(error) {});
    },
    reissueRequest(requestType) {
      let formData = {
        id: this.currentAccount.card.id,
        reason: requestType //"RP" reemitere PIN
        //"RC" reemitere CARD
      };
      httpServiceBeneficiary.beneficiaryService
        .setStateOrReasonCard(formData, this.accessToken)
        .then(response => {
          if (response.status === 200) {
            this.showModalPopup("modal_popup_confirmCardRequest", false);
              this.cardSuccess = true;
          }
        })
        .catch(error => {
          if (
            error.response.status === 400 &&
            error.response.data.description === "Has other reissue request"
          ) {
              this.cardRequestMessage = true;
              this.showModalPopup("modal_popup_confirmCardRequest", false);
          } else if (
            error.response.status === 409 &&
            error.response.data.error_code === "CRBS"
          ) {
            this.showModalPopup("modal_popup_confirmCardRequest", false);
          }
        });
    }
  },
  mounted() {
    this.cardSuccess = false;
  },
  watch: {
    accounts: function() {
      this.currentAccount = this.accounts[0];
    }
  },
  computed: {
    getProductName(productId) {
      return this.getProductName(productId);
    },
    getTransactionImage(transaction) {
      if (transaction.transaction_status === "REJECTED") {
        return "../../assets/images/card-tranzactie.svg";
      } else if (transaction.transaction_status === "ACCEPTED") {
        if (transaction.transaction_type === "DEBIT") {
          return "../../assets/images/card-reincarare.svg";
        } else {
          return "../../assets/images/card-tranzactie.svg";
        }
      } else if (transaction.transaction_status === "UNLOADED") {
        // unloaded
        return "../../assets/images/card-tranzactie-procram.svg";
      } else {
        return "../../assets/images/card-tranzactie.svg";
      }
    },
    getTransactionAmountClass(transaction) {
      if (transaction.transaction_status === "REJECTED") {
        return "red";
      } else if (transaction.transaction_status === "ACCEPTED") {
        if (transaction.transaction_type === "DEBIT") {
          return "green";
        } else {
          return "red";
        }
      } else if (transaction.transaction_status === "UNLOADED") {
        // unloaded
        return "";
      } else {
        return "";
      }
    },
    getTabClass(accountId) {
      return accountId === this.currentAccount.id && "active";
    }
  },
  created() {
    this.currentAccount = this.accounts[0];
  }
};
</script>
<style scoped lang="scss">
.nav-tabs {
  width: 60%;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  align-items: center;
  height: 53px;
  grid-column-gap: 5px;
}

.tabs a {
  padding-top: 15px;
  height: 50px;
  display: block;
  background: #ddd;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tabs a:hover {
  background: #fff;
}

.tabs a.active {
  background: #fff !important;
}
</style>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.middle-placed-content {
  position: relative;
  right: 0px;
  height: calc(100% - 68px);
  padding: 40px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
}

.cards-container {
  width: 90% !important;
  display: grid;
  min-height: 40px;
  background: #fff;
  margin: 0px auto;
  grid-template-columns: 40% 60%;
  border-radius: 5px;
  border-bottom: 2px solid #ddd;
  font-size: 16px;
  line-height: normal;
}

.cards-details {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  text-align: center;
  padding: 20px 0;
}
.cards-details a {
  text-decoration: none;
  font-weight: 700;
  color: #b1b1b1;
}

.carditem {
  width: 90%;
  height: 72px;
  border-radius: 4px;
  border-bottom: 2px solid #ddd;
  display: block;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.validity {
  clear: both;
  color: #b1b1b1;
  position: absolute;
  bottom: 10px;
}

@media (max-width: 1003px) {
  .cards-container {
    display: flex;
    flex-direction: column;
  }
}
</style>
