<template>
  <div>
    <div class="banner">
      <carousel :items-to-show="1"  :wrap-around="true" :autoplay="affiliateBanners > 1 ? 20000 : 0">
            <slide v-for="(entry, index) in affiliateBanners" :key="index">
              <!-- <template v-slot:img> -->
                <a :href="entry.link" target="_blank" @click="
                  sendClickToGA(entry.category, entry.action, entry.label)
                  ">
                  <img :src="require('@/assets/images/banner/' + entry.fileName)" />
                </a>
              <!-- </template> -->
            </slide>

            <template #addons>
              <navigation />
            </template>
          </carousel>
    </div>
    <br />

    <!-- <div class="notification desktop" v-if="okForAdditional">
      <b-container fluid class="border-box">
        <b-row class="border-box">
          <b-col class="border-box" xl="12" md="12">
            <img class="d-block mx-auto mb-3" src="@/assets/images/banner/afiliat/tazz.png" />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="border-box" xl="8" md="8">
            <h2>
              {{ $t("title.signAdditionalActAffiliate") }}
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="border-box" xl="9" md="9">
            <small>{{ $t("title.requestNotifEmailLabel") }}</small>
            <form @submit.prevent="
              ">
              <input id="email_notif" type="text" class="formedituser" v-model="emailNotif" />
            </form>
          </b-col>
          <b-col class="border-box" xl="3" md="3">
            <a class="notification-action" @click="requestAdditionalAAct()">
              {{ $t("title.requestAdditionalTextButton") }}
            </a>
          </b-col>
        </b-row>
        <br />
      </b-container>
    </div> -->

    <div class="tabs-container">
      <div class="tabs">
        <a v-for="(entry, index) in affiliate.products" :key="entry.name" @click="selectProduct(index)"
          :class="entry.name === selectedProduct.name && 'active'">
          <img :src="require('@/assets/images/logo-up-' +
            (entry.card_profile
              ? entry.card_profile
              : entry.card
            ) +
            '.png')
            " />
        </a>
      </div>
    </div>

    <div class="allstats-affiliate">
      <div class="content-allstats">
        <div class="balance-circle mobile" :class="'border-color-' +
          selectedProduct.card

          ">
          <img :src="require('@/assets/images/logo-up-' +
            selectedProduct.card
            +
            '.png')
            " />
        </div>
        <div class="arrow-left" @click="previousProduct()">
          <img src="@/assets/images/arrow-left.svg" />
        </div>

        <div class="arrow-right" @click="nextProduct()">
          <img src="@/assets/images/arrow-right.svg" />
        </div>
        <br clear="all" />
      </div>
      <div class="navigator mobile">
        <div v-for="(entry, index) in affiliate.products" :key="index" @click="selectProduct(index)" :class="entry.name === selectedProduct.name ? 'bubble' : 'bubble-stroke'
          "></div>
      </div>
    </div> 
    <!-- <div class="container-alb mt-0">
      <div class="filter">
        <select @change="selectPeriod" v-model="selectedPeriod">
          <option value="1">{{ $t("title.transactionStatsDDLLastMonth") }}</option>
          <option value="3">
            {{ $t("title.transactionStatsDDLLastMonths", { index: 3 }) }}
          </option>
          <option value="6">
            {{ $t("title.transactionStatsDDLLastMonths", { index: 6 }) }}
          </option>
          <option value="9">
            {{ $t("title.transactionStatsDDLLastMonths", { index: 9 }) }}
          </option>
          <option value="12">
            {{ $t("title.transactionStatsDDLLastMonths", { index: 12 }) }}
          </option>
        </select>
      </div>
      <br />
      <br />
      <div class="chart-wrapper w-40">
        <apexchart type="radialBar" :options="chartRadialOptions" :series="seriesForRadial">
        </apexchart>
      </div>
      <div class="chart-wrapper w-60">
        <apexchart type="line" :options="chartLineOptions" :series="seriesForLine">
        </apexchart>
      </div>
    </div> -->

    <div class="mobile-banner">
      <carousel :items-to-show="1"  :wrap-around="true" :autoplay="affiliateBanners > 1 ? 20000 : 0">
            <slide v-for="(entry, index) in affiliateBanners" :key="index">
              <!-- <template v-slot:img> -->
                <a :href="entry.link" target="_blank" @click="
                  sendClickToGA(entry.category, entry.action, entry.label)
                  ">
                  <img :src="require('@/assets/images/banner/' + entry.fileName)" />
                </a>
              <!-- </template> -->
            </slide>

            <template #addons>
              <navigation />
            </template>
          </carousel>
    </div>

    <div class="container-alb">
      <p class="invoice-title">
        <strong>{{ $t("title.lastInvoicesTitle") }}</strong>
      </p>
      <br />
      <div v-if="this.affiliateLastInvoices && this.affiliateLastInvoices.length > 0
        ">
        <InvoiceTableDesktop :company="this.affiliate" :companyInvoices="this.affiliateLastInvoices"  :userRoleInvTable="this.affiliate.role" />
        <div class="text-center mt-5">
          <a :href="'/affiliate/invoices?product=' + this.selectedProduct.card" class="notification-action">{{
            $t("title.seeAllInvoicesButtonLabel") }}</a>
        </div>
      </div>
      <div class="error-wrapper border-box" v-else>
        {{ $t("title.noInvoicesMessage") }}
      </div>
    </div>

    <div class="banner-stats">
      <div class="circle2 stats-circle">
        <h1>
          {{ filterDashboardInfos("MEDIUM_TRANS") }} {{ $t("title.currency") }}
        </h1>
        <small>{{ $t("title.mediumCartText") }}</small>
      </div>
      <div class="circle1 stats-circle">
        <h1>
          {{ filterDashboardInfos("TOTAL_HOLDERS") }}
        </h1>
        <small>{{ $t("title.monthlyVisitsText") }}</small>
      </div>
      <div class="banner-square">
        <carousel :items-to-show="1"  :wrap-around="true" :autoplay="3000"  style="width: 100%; height: 100%;">
          <slide v-for="(entry, index) in crossSell" :key="index">
              <!-- <template v-slot:img> -->
                <a :href="entry.link" target="_blank">
                  <img :src="require('@/assets/images/' + entry.fileName)" style="width: 100%; height: 100%;"  />
                </a>
              <!-- </template> -->
            </slide>
            <!-- <slide v-for="slide in 2" :key="slide" style="width: 100%; height: 100%;">
                <img :src="require('@/assets/images/cross sell ' + slide + '.jpeg')" style="width: 100%; height: 100%;" />
            </slide> -->
          </carousel>
      </div>
      <br clear="all" />
    </div>


    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('title.loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceTableDesktop from "@/components/company/InvoiceTableDesktop.vue";
import affiliateBanner from "@/assets/json/affiliate_banner.js";
import crossSell from "@/assets/json/cross_sell.js";
import LinkFrame from "@/components/beneficiary/LinkFrame.vue";
import fieldValidation from "@/plugins/fieldValidation";
import { Carousel, Slide, Navigation } from 'vue3-carousel'

import httpServiceAffiliate from "@/http/http-affiliate.js";
import httpServiceUser from "@/http/http-user.js";
import moment from "moment";
import Cookies from "js-cookie";
import {
  BContainer,
  BRow,
  BCol,
} from "bootstrap-vue-3";
import VueApexCharts from "vue-apexcharts";
import utils from "@/utils.js";

export default {
  setup() {
    const { getCompanyProductName } = utils();
    return {
      getCompanyProductName,
    }
  },
  props: {
    affiliate: Object,
    user: Object,
    msg: String
  },
  components: {
    InvoiceTableDesktop,
    LinkFrame,
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol,
    apexchart: VueApexCharts,
    Carousel,
    Slide,
    Navigation
  },
  data() {
    return {
      affiliateBanners: affiliateBanner,
      crossSell: crossSell,
      affiliateDataLoaded: false,
      affiliateBeneficiaries: null,
      selectedProduct: null,
      affiliateLastInvoices: null,
      productIndex: 0,
      config: JSON.parse(sessionStorage.getItem("affiliate_jsonFile")),
      selectedPeriod: 3,
      transactionChartData: null,
      transactionMapData: null,
      dashboardInfos: null,
      dashboardError: null,
      okForAdditional: false,
      chartLineOptions: {},
      seriesForLine: [
        {
          name: "Valoare tranzactie",
          data: []
        },
        {
          name: "Numar tranzactii",
          data: []
        }
      ],
      seriesForRadial: [],
      chartRadialOptions: {},
      selectedTypeTicket: null,
      detailsMobile: false,
      uuid: "",
      emailNotif: ""
    };
  },
  methods: {
    selectProduct(index) {
      this.selectedProduct = this.affiliate.products[index];
      this.transactionMapData = null;
      this.totalTransactionMap = 0;
      this.transactionMapData = null;
      this.dashboardInfos = null;
      this.getAffiliateDashboardInfo();
    },
    previousProduct() {
      let numberOfProduct = this.affiliate.products.length;
      this.productIndex--;
      if (this.productIndex < 0) {
        this.productIndex = numberOfProduct - 1;
      }
      this.selectProduct(this.productIndex);
    },
    nextProduct() {
      let numberOfProduct = this.affiliate.products.length;
      this.productIndex++;
      if (this.productIndex === numberOfProduct) {
        this.productIndex = 0;
      }
      this.selectProduct(this.productIndex);
    },

    getAffiliateDashboardInfo() {
      this.getLastInvoices();
      this.getDashboardGeneralInfos();
    },
    getLastInvoices() {
      var invoicesData =
        "?type=" +
        "affiliate" +
        "&brand=" +
        this.selectedProduct.brand +
        "&paid=P,U" +
        "&start_date=" +
        "20200101" +
        "&end_date=" +
        moment().format("YYYYMMDD") +
        "&per_page=2" +
        "&page=1" +
        "&desc=date" +
        "&sort=desc";

      httpServiceUser.userService
        .getCompanyInvoices(
          this.affiliate.company_id,
          invoicesData,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .catch(error => {
          this.dashboardError = {
            code: error.response.data,
            description: "Error fetching last invoices"
          };
          this.totalTransactionMap = 0;
        })
        .then(response => {
          if (response.status == 200) {
            this.affiliateLastInvoices = response.data;
          } else if (response.status == 400 || response.status == 403) {
            this.dashboardError = {
              code: response.data,
              description: "Error fetching last invoices"
            };
            this.totalTransactionMap = 0;
          } else {
            this.dashboardError = { description: response.description };
            this.totalTransactionMap = 0;
          }
        });
    },

    getDashboardGeneralInfos() {
      var formData =
        "?customer_id=" +
        this.affiliate.company_id +
        "&fiscal=" +
        this.affiliate.fiscal_code +
        "&brand=" +
        this.selectedProduct.brand;

      httpServiceAffiliate.affiliateService
        .getDashboardInfos(
          this.affiliate.company_id,
          formData,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.dashboardInfos = response.data;
          } else if (response.status == 400 || response.status == 403) {
            this.dashboardError = {
              code: response.data,
              description: "Error fetching dashboard info data"
            };
          } else {
            this.dashboardError = { description: response.description };
          }
        })
        .catch(error => {
          this.dashboardError = {
            code: error.response.data,
            description: "Error fetching dashboard info data"
          };
        });
    },
    getStyle(width) {
      return "width:" + width + "%";
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    showOrHideFirstPopup(modal_id) {
      Cookies.set("first", true);
      this.showModalPopup(modal_id, false);
    },
    selectPeriod(event) {
      this.selectedPeriod = event.target.value;
    },
    displayDate(date) {
      return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format("DD/MM/YYYY");
    },
    updateChartLine() {
      var trans = [];
      var date = [];
      var transNumber = [];
      this.transactionMapData.forEach(tr => {
        trans.push(tr.transactions_amount);
        date.push(this.displayDate(tr.transactions_date));
        transNumber.push(parseInt(tr.transactions_number));
      });
      this.seriesForLine = [{ data: trans }, { data: transNumber }];
      this.chartLineOptions = {
        chart: {
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ["#f79000", "#864e00"],
        xaxis: {
          categories: date,
          labels: {
            rotate: -45,
            style: {
              fontFamily: "Roboto"
            }
          },
          tickPlacement: "on"
        },
        yaxis: [
          {},
          {
            opposite: true
          }
        ],
        legend: {
          show: true,
          position: "top"
        },
        stroke: {
          width: 2
        }
        // responsive: [
        //   {
        //     breakpoint: 380,
        //     options: {
        //       xaxis: {
        //         labels: {
        //           rotate: -45
        //         }
        //       },
        //       yaxis: {
        //         show: false
        //       }
        //     }
        //   }
        // ]
      };
    },
    updateChartRadial() {
      var transCard = this.transactionChartData[0]
        ? this.transactionChartData[0].transactions_amount
        : 0;
      var card = transCard > 0 ? ((transCard * 100) / max).toFixed(0) : 0;
      this.seriesForRadial = [card];
      this.chartRadialOptions = {
        chart: {
          type: "radialBar"
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              size: "70%",
              margin: 20,
              background: "transparent",
              image: undefined
            },
            dataLabels: {
              name: {
                fontSize: "20px",
                color: "#864e00",
                offsetY: -30
              },
              total: {
                color: "#864e00",
                show: true,
                label: "Venituri aduse",
                formatter: function (w) {
                  return transCard + " lei";
                }
              }
            }
          }
        },
        colors: ["#864e00"],
        labels: ["Tranzactii card"],
        legend: {
          show: true,
          floating: true,
          fontSize: "10px",
          position: "left",
          offsetX: 0,
          offsetY: 0,
          labels: {
            useSeriesColors: true
          },
          markers: {
            width: 5,
            height: 5
          },
          itemMargin: {
            vertical: 0
          }
        }
      };
    },
    displayDateAndTime(date) {
      return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format(
        "DD/MM/YYYY hh:mm:ss"
      );
    },
    getProductNameMethod(productId) {
      return this.getCompanyProductName(productId, this.affiliate.products);
    },
    filterDashboardInfos(name) {
      if (this.dashboardInfos) {
        for (let index in this.dashboardInfos) {
          if (this.dashboardInfos[index].name === name) {
            return this.dashboardInfos[index].value;
          }
        }
      }
      return 0;
    },
    sendClickToGA(category, action, label) {
      if (this.$ga) {
        this.$ga.event({
          eventCategory: category,
          eventAction: action,
          eventLabel: label
        });
      }
    },
  },
  mounted() {
    if (!Cookies.get("first")) {
      var now = moment();
      if (now.hour() < 7) {
        this.showModalPopup("modal_popup_error", true);
      }
    }
  },
  created() {
    this.selectProduct(0);
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.allstats-affiliate {
  width: 100%;
  background: #fff;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.chart-wrapper {
  position: relative;
  display: inline-block;
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

/* .offers {
  width: 95%;
  margin: 0 auto;
} */
.offer {
  /* padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px; */
}
</style>
