<template>
  <div class="content">
    <div class="faq-placed-content">
      <div class="services-container">
        <div class="cards-listing">
          <h3>{{ $t("title.servicesPageTitle") }}</h3>
          <div>
            <a
              v-for="(servItem, index) in serviceItems"
              :key="index"
              class="service-item border-left border-left-color-11"
              :class="[
            servItem.id === currentService.id
              ? 'card-active'
              : 'card-inactive'
          ]"
              @click="selectService(index)"
            >
              <div class="logo-card">
                <img :src="servItem.imageURL" />
              </div>
              <div class="service-name">
                {{ $t("title.serviceName" + servItem.id) }}
              </div>
            </a>
          </div>
        </div>

        <div class="services-details view">
          <div class="services-content" v-if="currentService.id === 1">
            <h3>{{ $t("title.serviceName1") }}</h3>

            <p>{{ $t("title.textRecommendAParterner") }}</p>
            <form
              id="formRecomandedAPartner"
              @submit.prevent="submitFormRecommendAPartner"
            >
              <h3 class="orange">{{ $t("title.titleYourCoordinates") }}</h3>
              <input
                type="text"
                v-model="formRecomandedAPartner.employee"
                required
                :placeholder="[[$t('title.placeholderNameAndSurname')]]"
              />
              <input
                type="text"
                v-model="formRecomandedAPartner.phone"
                required
                :placeholder="[[$t('title.placeholderPhone')]]"
              />
              <input
                type="text"
                v-model="formRecomandedAPartner.employer"
                :placeholder="[[$t('title.placeholderEmployerName')]]"
              />
              <input
                type="text"
                v-model="formRecomandedAPartner.email"
                required
                :placeholder="[[$t('title.placeholderEmail')]]"
              />
              <h3 class="orange">{{ $t("title.titleRecommendedCompanyDetails") }}</h3>
              <input
                type="text"
                v-model="formRecomandedAPartner.company"
                required
                :placeholder="[[$t('title.placeholderCompanyName')]]"
              />
              <input
                type="text"
                v-model="formRecomandedAPartner.domain"
                :placeholder="[[$t('title.placeholderFieldOfActivity')]]"
              />
              <input
                type="text"
                v-model="formRecomandedAPartner.county"
                required
                :placeholder="[[$t('title.placeholderDistrict')]]"
              />
              <input
                type="text"
                v-model="formRecomandedAPartner.locality"
                required
                :placeholder="[[$t('title.placeholderCity')]]"
              />

              <vue-recaptcha
                ref="recaptcha1"
                @verify="getRecaptchaRP"
                @expired="onCaptchaExpired(recaptcha1)"
                sitekey="6Ldgha4UAAAAAM7NEpxPWalbagovNHZ55A10JXii"
                :loadRecaptchaScript="true" 
                language="ro"
              ></vue-recaptcha>
              <!-- <br />
              <label>
                <b-form-checkbox
                  id="acceptGDPR"
                  v-model="acceptGDPR"
                  name="acceptGDPR"
                >
                  {{ $t("title.textAcceptPrivacyPolicy") }}
                  <a href="/beneficiary/gdpr" class="orange">{{
                    $t("title.textPrivacyPolicy")
                  }}</a>
                </b-form-checkbox>
              </label> -->
              <input type="submit" :value="[[$t('title.textButtonForm')]]" />
            </form>
          </div>

          <div class="services-content" v-else-if="currentService.id === 2">
            <br />
            <br />
            <h3 align="center">{{ $t("title.downloadApps") }}</h3>
            <p align="center">
              <span class="app-platform">IOS App</span>
              <br />
              <a
                href="https://apps.apple.com/ro/app/up-mobil-moldova/id1542085076?l=ro"
              >
                <img
                  class="app-store"
                  src="../../assets/images/qrcode-iOS.png"
                />
              </a>
            </p>
            <p align="center">
              <span class="app-platform">Android App</span>
              <br />
              <a
                href="https://play.google.com/store/apps/details?id=com.solde.md"
              >
                <img
                  class="google-play"
                  src="../../assets/images/qrcode-android.png"
                />
              </a>
            </p>
          </div>

          <!-- <div class="services-content" v-else-if="currentService.id === 3">
            <h3>{{ $t("title.serviceName3") }}</h3>
            <p>{{ $t("title.textMemberGetMember") }}</p>
            <p>
              <img src="../../assets/images/schema.svg" width="100%" />
            </p>
            <form
              id="formMemberGetMember"
              @submit.prevent="submitFormMemberGetMember"
            >
              <h3 class="orange">{{ $t("title.titleYourCoordinates") }}</h3>
              <input
                type="text"
                v-model="formMemberGetMember.mgmYourName"
                required
                :placeholder="[[$t('title.placeholderMemberName')]]"
              />
              <input
                type="text"
                v-model="formMemberGetMember.mgmYourCompanyName"
                required
                :placeholder="[[$t('title.placeholderMemberCompanyName')]]"
              />
              <input
                type="text"
                v-model="formMemberGetMember.mgmYourRole"
                required
                :placeholder="[[$t('title.placeholderMemberRole')]]"
              />
              <input
                type="text"
                v-model="formMemberGetMember.mgmYourDistrict"
                required
                :placeholder="[[$t('title.placeholderMemberDistrict')]]"
              />
              <input
                type="text"
                v-model="formMemberGetMember.mgmYourCity"
                required
                :placeholder="[[$t('title.placeholderMemberCity')]]"
              />
              <input
                type="text"
                v-model="formMemberGetMember.mgmYourPhone"
                required
                :placeholder="[[$t('title.placeholderMemberPhone')]]"
              />
              <input
                type="text"
                v-model="formMemberGetMember.mgmYourEmail"
                :placeholder="[[$t('title.placeholderMemberEmail')]]"
              />
              <h3 class="orange">{{ $t("title.titleRecommendedCompanyDetails") }}</h3>
              <input
                type="text"
                v-model="formMemberGetMember.mgmCompCompanyName"
                required
                :placeholder="[[$t('title.placeholderMemberCompanyName')]]"
              />
              <input
                type="text"
                v-model="formMemberGetMember.mgmCompContactPerson"
                :placeholder="[[$t('title.placeholderMemberName')]]"
              />
              <input
                type="text"
                v-model="formMemberGetMember.mgmCompRole"
                :placeholder="[[$t('title.placeholderMemberRole')]]"
              />
              <input
                type="text"
                v-model="formMemberGetMember.mgmCompDistrict"
                required
                :placeholder="[[$t('title.placeholderMemberDistrict')]]"
              />
              <input
                type="text"
                v-model="formMemberGetMember.mgmCompCity"
                required
                :placeholder="[[$t('title.placeholderMemberCity')]]"
              />
              <input
                type="text"
                v-model="formMemberGetMember.mgmCompPhone"
                required
                :placeholder="[[$t('title.placeholderMemberPhone')]]"
              />
              <input
                type="text"
                v-model="formMemberGetMember.mgmCompEmail"
                :placeholder="[[$t('title.placeholderMemberEmail')]]"
              />
              <vue-recaptcha
                ref="recaptcha2"
                @verify="getRecaptchaMGM"
                @expired="onCaptchaExpired(recaptcha2)"
                sitekey="6Ldgha4UAAAAAM7NEpxPWalbagovNHZ55A10JXii"
                :loadRecaptchaScript="true" 
                language="ro"
              ></vue-recaptcha>
              <br />

              <label>
                <input type="checkbox" required />
                {{ $t("title.textReadAndAgree") }}
                <a
                  href="https://upromania.ro/html/docs/recomandare/regulament.pdf?r=2"
                  target="_blank"
                  class="orange"
                  >{{ $t("title.textCampaignRegulations") }}</a
                >
              </label>
              <input type="submit" :value="[[$t('title.textButtonForm')]]" />
            </form>
          </div> -->

          <!-- offers option -->
          <!-- <div class="services-content" v-else-if="currentService.id === 4">
            <h3>{{ $t("title.serviceName4") }}</h3>
            <form class="form-group" id="formMemberGetMember" @submit.prevent>
              <select class="form-control" @change="offersProductSelected">
                <option
                  v-for="(entry, index) in this.accounts"
                  :key="index"
                  :value="entry.product"
                  :selected="isSelectedProduct(entry.product)"
                  >{{ getProductNameMethod(entry.product) }}</option
                >
              </select>
            </form>
            <div v-if="!offersList || offersList.length === 0" class="offer">
              {{ $t("title.noOffersMessage") }}
            </div>
            <div
              v-for="offer in offersList"
              :key="offer.offer_id"
              class="offer"
            >
              <div class="offer-content">
                <div>
                  <img class="offer-image" :src="offer.photo" />
                </div>
                <div>
                  <div class="offer-name">{{ offer.affiliates[0].name }}</div>
                  <p class="offer-address">
                    {{ offer.affiliates[0].address.street_name }},
                    {{ offer.affiliates[0].address.locality }},
                    {{ offer.affiliates[0].address.county }}
                  </p>
                  <read-more
                    class="offer-description"
                    :moreStr="$t('title.readMore')"
                    :text="offer.description_long"
                    link="#"
                    :lessStr="$t('title.readLess')"
                    :max-chars="100"
                  ></read-more>
                  <p class="offer-discount">
                    <small v-if="offer.discount !== 0">{{
            "Discount:" + offer.discount + "%"
          }}</small>
                  </p>
                </div>
              </div>
            </div>
          </div> -->

          <div class="services-content" v-else-if="currentService.id === 5">
            <a
              href="https://upmoldova.md/harta-afiliati/"
              target="_blank"
            >
              <img
                src="../../assets/images/maps.jpg"
                alt="PARTNER NETWORK AND MAPS"
              />
            </a>
          </div>

          <div class="services-content" v-if="currentService.id === 6">
            <h3>{{ $t("title.serviceName6") }}</h3>
            <p>{{ $t("title.textFeedback") }}</p>
            <form id="formFeedback" @submit.prevent="submitFormFeedback">
              <input
                type="text"
                v-model="formFeedback.rapName"
                required
                :placeholder="[[$t('title.placeholderNameAndSurname')]]"
              />
              <input
                type="text"
                v-model="formFeedback.rapEmail"
                required
                :placeholder="[[$t('title.placeholderEmail')]]"
              />
              <textarea
                v-model="formFeedback.rapComment"
                required
                :placeholder="[[$t('title.placeholderComment')]]"
              />
              <vue-recaptcha
                ref="recaptcha3"
                @verify="getRecaptchaF"
                @expired="onCaptchaExpired(recaptcha3)"
                sitekey="6Ldgha4UAAAAAM7NEpxPWalbagovNHZ55A10JXii"
                :loadRecaptchaScript="true" 
                language="ro"
              ></vue-recaptcha>
              <br />
              <!-- <label>
                <input type="checkbox" />
                {{ $t("title.textAcceptPrivacyPolicy") }}
                <a href="/beneficiary/gdpr" class="orange">{{
                  $t("title.textPrivacyPolicy")
                }}</a>
              </label> -->
              <input type="submit" :value="[[$t('title.textButtonForm')]]" />
            </form>
          </div>
        </div>
        <div id="modal_popup_emailSuccess" class="modal-popup">
          <div class="modal-popup-content">
            <a
              @click="showModalPopup('modal_popup_emailSuccess', false)"
              class="close-button"
            >
              <img src="../../assets/images/close-button.svg" />
            </a>
            <p v-html="$t('title.msgEmailSuccess')"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import httpServiceBeneficiary from "../../http/http-beneficiary.js";
  import httpServiceCommon from "../../http/http-common.js";
  import utils from "@/utils.js";
  // import Vue from "vue";
  import ReadMore from "vue-read-more";
  import VueRecaptcha from "vue-recaptcha-v3";
  import { BFormCheckbox } from "bootstrap-vue-3";
  // Vue.use(ReadMore);

  export default {
    setup() {
      const { getProductName, getUrlParam } = utils();

      return {
        getProductName,
        getUrlParam
      }
    },
    components: {
      VueRecaptcha,
      "b-form-checkbox": BFormCheckbox
    },
    props: {
      selectedService: Array,
      accessToken: String,
      recommandPartnerLink: String,
      beneficiaryCardData: Object,
      msg: String,
      accounts: Array
    },
    data() {
      return {
        serviceItems: [
          {
            id: 1,
            imageURL: require("../../assets/images/reccomend-partner.svg")
          },
          {
            id: 2,
            imageURL: require("../../assets/images/download-app.svg")
          },
          // {
          //   id: 3,
          //   imageURL: require("../../assets/images/mgm.svg")
          // },
          {
            id: 4,
            imageURL: require("../../assets/images/dedicated-offers.svg")
          },
          {
            id: 5,
            imageURL: require("../../assets/images/partner-network.svg")
          },
          {
            id: 6,
            imageURL: require("../../assets/images/feedback.svg")
          }
        ],
        currentService: null,
        selectedProduct: this.accounts[0].product,
        formRecomandedAPartner: {
          template: "app.user.recommend",
          company: "",
          domain: "",
          county: "",
          locality: "",
          employee: "",
          phone: "",
          employer: "",
          email: "",
          recaptcha: ""
        },
        formMemberGetMember: {
          template: "app.user.recommend.prospect",
          mgmYourName: "",
          mgmYourCompanyName: "",
          mgmYourRole: "",
          mgmYourDistrict: "",
          mgmYourCity: "",
          mgmYourPhone: "",
          mgmYourEmail: "",
          mgmCompCompanyName: "",
          mgmCompContactPerson: "",
          mgmCompRole: "",
          mgmCompDistrict: "",
          mgmCompCity: "",
          mgmCompPhone: "",
          mgmCompEmail: "",
          recaptcha: "",
          url: "beneficiary/services"
        },
        formFeedback: {
          template: "app.user.feedback",
          rapBeneficiaryId: this.beneficiaryCardData.id,
          rapName:
            this.beneficiaryCardData.first_name +
            " " +
            this.beneficiaryCardData.last_name,
          rapEmail: this.beneficiaryCardData.email,
          rapComment: "",
          recaptcha: ""
        },
        lat: "44.435968",
        lon: "26.0993553",
        offersList: null,
        acceptGDPR: false
      };
    },
    methods: {
      selectService(index) {
        this.currentService = this.serviceItems[index];
        if (this.currentService === 3) {
          window.open(
            "https://upmoldova.md/recomanda-afiliat/",
            "_blank"
          );
        }
        // if (this.currentService.id === 4) {
        //   this.callGetOffers(this.selectedProduct, this.lon, this.lat);
        // }
      },
      showModalPopup(elementId, show) {
        if (show) {
          document.getElementById(elementId).style.visibility = "visible";
        } else {
          document.getElementById(elementId).style.visibility = "hidden";
        }
      },
      callGetOffers(product, longitude, latitude) {
        var formData =
          "?product=" +
          product +
          "&poi_latitude=" +
          latitude +
          "&poi_longitude=" +
          longitude +
          "&lang=" +
          localStorage.language;

        httpServiceBeneficiary.beneficiaryService
          .searchOffers(formData, this.accessToken)
          .then(function (response) {
            return response;
          })
          .catch(function (error) { })
          .then(response => {
            if (response.status == 200) {
              this.offersList = response.data;
            } else if (response.status == 400 || response.status == 403) {
              this.offersList = null;
            }
          });
      },
      getRecaptchaRP(recaptchaToken) {
        this.formRecomandedAPartner.recaptcha = recaptchaToken;
      },
      getRecaptchaMGM(recaptchaToken) {
        this.formMemberGetMember.recaptcha = recaptchaToken;
      },
      getRecaptchaF(recaptchaToken) {
        this.formFeedback.recaptcha = recaptchaToken;
      },
      submitFormRecommendAPartner() {
        // if (!this.acceptGDPR) {
        //   return;
        // }
        httpServiceCommon.commonsService
          .sendForm(this.formRecomandedAPartner)
          .then(response => {
            if (response.status === 200) {
              this.showModalPopup("modal_popup_emailSuccess", true);
              this.formRecomandedAPartner.company = "";
              this.formRecomandedAPartner.domain = "";
              this.formRecomandedAPartner.county = "";
              this.formRecomandedAPartner.locality = "";
              this.formRecomandedAPartner.employee = "";
              this.formRecomandedAPartner.phone = "";
              this.formRecomandedAPartner.employer = "";
              this.formRecomandedAPartner.email = "";
              this.$refs.recaptcha1.reset();
              this.acceptGDPR = false;
            }
            return response;
          })
          .catch(function (error) { });
      },
      submitFormFeedback() {
        let formData = {
          code: this.formFeedback.rapBeneficiaryId,
          description: this.formFeedback.rapComment
        };
        httpServiceBeneficiary.beneficiaryService
          .sendFeedbackMail(formData, this.accessToken)
          .then(function (response) {
            return response;
          })
          .then(response => {
            if (response.status == 200) {
              this.formFeedback.rapComment = "";
              this.showModalPopup("modal_popup_emailSuccess", true);

              // download content
            } else if (response.status == 400 || response.status == 403) {
              // this.sentFeedbackError = response.response;
            } else if (response.status == 404) {
              // show file not found
              // this.sentFeedbackError = response.response;
            }
          })
          .catch(error => {
            // this.sentFeedbackError = {
            //   code: error.response.status
            // };
          });
    },
    submitFormMemberGetMember() {
      httpServiceCommon.commonsService
        .sendForm(this.formMemberGetMember)
        .then(response => {
          if (response.status === 200) {
            this.showModalPopup("modal_popup_emailSuccess", true);
            this.formMemberGetMember.mgmYourName = "";
            this.formMemberGetMember.mgmYourCompanyName = "";
            this.formMemberGetMember.mgmYourRole = "";
            this.formMemberGetMember.mgmYourDistrict = "";
            this.formMemberGetMember.mgmYourCity = "";
            this.formMemberGetMember.mgmYourPhone = "";
            this.formMemberGetMember.mgmYourEmail = "";
            this.formMemberGetMember.mgmCompCompanyName = "";
            this.formMemberGetMember.mgmCompContactPerson = "";
            this.formMemberGetMember.mgmCompRole = "";
            this.formMemberGetMember.mgmCompDistrict = "";
            this.formMemberGetMember.mgmCompCity = "";
            this.formMemberGetMember.mgmCompPhone = "";
            this.formMemberGetMember.mgmCompEmail = "";
            this.$refs.recaptcha2.reset();
          }
          return response;
        })
        .catch(function (error) { });
    },
    offersProductSelected(event) {
      this.selectedProduct = event.target.value;
      this.callGetOffers(event.target.value, this.lon, this.lat);
    },
    getProductNameMethod(productId) {
      return this.getProductName(productId);
    },
    onCaptchaExpired(recaptcha) {
      recaptcha;
      this.$refs.recaptcha.reset();
    },
    isSelectedProduct(product) {
      return product + "" === this.selectedProduct;
    }
  },
  created() {
    this.currentService = this.serviceItems[0];
    let product = this.getUrlParam("product");
    if (product !== null) {
      this.currentService = this.serviceItems[3];
      this.selectedProduct = product;
      this.callGetOffers(product, this.lon, this.lat);
    }
    let download = this.getUrlParam("download");
    if (download !== null) {
      this.currentService = this.serviceItems[download];
    }
  },
  mounted() { }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
  .offer {
    float: left;
    width: 50%;
  }

  .offer:nth-child(2n - 1) {
    clear: both;
  }

  .offer-content {
    margin: 10px 10px 10px 0;
    padding: 10px;
    border: 1px solid #ced4da;
  }

  .offer:nth-child(2n) .offer-content {
    margin-right: 0;
    margin-left: 10px;
  }

  .offer-name {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
  }

  .offer-image {
    width: 100%;
    font-size: 12px;
    font-style: italic;
    margin: 0;
    color: #9b9a9a;
  }

  .offer-address {
    font-size: 12px;
    font-style: italic;
    font-weight: bold;
    margin: 5px 0;
    color: #9b9a9a;
    min-height: 54px;
  }

  .offer-description {
    font-size: 12px;
    font-style: italic;
    margin: 5px 0;
    color: #9b9a9a;
  }

  .offer-description>p {
    min-height: 72px;
  }

  .offer-discount {
    font-size: 16px;
    font-weight: bold;
    color: red;
    min-height: 24px;
  }

  .offer-discount small {
    font-weight: bold;
  }

  .app-store {
    width: 150px;
  }

  .google-play {
    width: 150px;
  }

  @media (max-width: 400px) {
    .app-store {
      width: 100%;
    }

    .google-play {
      width: 100%;
    }
  }

  @media (max-width: 1366px) {
    .offer {
      width: 100%;
    }

    .offer-content {
      margin: 10px 0;
    }

    .offer:nth-child(2n) .offer-content {
      margin: 10px 0;
    }
  }

  @media (max-width: 1003px) {
    .offer {
      width: 50%;
    }

    .offer-content {
      margin: 10px 10px 10px 0;
    }

    .offer:nth-child(2n) .offer-content {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  @media (max-width: 600px) {
    .offer {
      width: 100%;
    }

    .offer-content {
      margin: 10px 0;
    }

    .offer:nth-child(2n) .offer-content {
      margin: 10px 0;
    }
  }
</style>
