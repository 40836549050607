<template>
  <div>
    <div class="delivery-title left" v-if="window.width > 1003">
      <h1>{{ $t("title.workingPoints") }}</h1>
      <p>{{ $t("title.workingPointsDescription") }}</p>
    </div>
    <!-- <div class="right">
      <a
        v-if="
          affiliate &&
            affiliate.role !== 'FAC' &&
            !affiliate.state_owned &&
            affiliate.user_config &&
            affiliate.user_config.roles &&
            affiliate.user_config.roles.includes('EDITOR')
        "
        class="notification-action mb-3"
        @click="addNewWorkingPoint()"
        >{{ $t("title.workingPointButtonAdd") }}</a
      >
    </div> -->
    <br clear="all" />
    <br clear="all" />
    <div class="content-delivery">
      <!-- <div class="second-tabs">
        <form @submit.prevent="
          ">
          <p>
            <strong>{{ $t("title.workingPointSelectLabel") }}</strong>
          </p>
          <select class="filtrare" @change="subsidiarySelected">
            <option selected :value="0">{{ $t("title.selectLabel") }}</option>
            <option v-for="(entry, index) in this.workingPointAddress" :key="index" :value="entry.codcom"
              :selected="isSelectedSubsidiary(entry.codcom)">{{ customLabel(entry) }}</option>
          </select>
        </form>
      </div> -->

      <div class="second-tabs">
        <div v-if="addressPoints === null || addressPoints.length === 0" class="loading">
          <img src="@/assets/images/loading.gif" />
        </div>
        <p v-if="noAddressPoints">
          {{ $t("title.deliveryNoHasAddress") }}
        </p>
        <div v-for="(point, index) in filterWorkingPoints" :key="index">
          <h3>{{ point.name }} - {{ point.address.street_name }}</h3>
          <div class="grey-info">
            <b-container fluid class="border-box">
              <form class="margin-paragraph" @submit.prevent="
                ">
                <b-row class="border-box">
                  <b-col class="border-box" xl="12" md="12">
                    <p class="title-address-table">
                      <strong>{{ $t("title.deliveryPointAddressTitle") }}</strong>
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="4" md="4">
                    <p>
                      <small>{{ $t("title.workingPointAddress") }}</small>
                      <br />
                      <input v-if="point !== selectedPoint" type="text" v-model="point.address.street_name"
                        class="formedituser" maxlength="64" disabled />
                      <input v-else type="text" v-model="point.succursal.address.street_name" class="formedituser"
                        maxlength="64" @keyup="updateInputCharacters()" />
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="2" md="2">
                    <small>{{ $t("title.deliveryPointAddressDistrict") }}</small>
                    <br />
                    <input v-if="point !== selectedPoint" type="text" v-model="point.address.county" class="formedituser"
                      disabled />
                    <!-- <multiselect
                      v-else
                      v-model="point.address.county_obj"
                      :options="county"
                      class="margin-top-1rem"
                      :placeholder="$t('title.selectLabel')"
                      select-label=""
                      selected-label=""
                      deselect-label=""
                      track-by="id"
                      label="value"
                      :loading="isLoading"
                      @input="
                        getNomenclatures(
                          'locality',
                          point.address.county_obj.id
                        )
                      "
                    /> -->
                  </b-col>
                  <b-col class="border-box" xl="2" md="2">
                    <small>{{ $t("title.deliveryPointAddressCity") }}</small>
                    <br />
                    <input v-if="point !== selectedPoint" type="text" v-model="point.address.locality"
                      class="formedituser" disabled />
                    <!-- <multiselect
                      v-else
                      v-model="point.address.locality_obj"
                      :options="locality"
                      class="margin-top-1rem"
                      :placeholder="$t('title.selectLabel')"
                      select-label=""
                      selected-label=""
                      deselect-label=""
                      track-by="id"
                      label="value"
                      :loading="isLoading"
                      :disabled="point !== selectedPoint"
                      @open="locality = map.get('locality')"
                      @input="updateMultiSelect()"
                    /> -->
                  </b-col>
                  <b-col class="border-box" xl="2" md="2">
                    <p>
                      <small>
                        {{ $t("title.workingPointGPSLon") }}
                      </small>
                      <br />
                      <input v-if="point !== selectedPoint" type="text" v-model="point.address.gps_longitude"
                        class="formedituser" disabled />
                      <input v-else type="text" v-model="point.succursal.address.gps_longitude" class="formedituser"
                        @keyup="updateInputCharacters()" />
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="2" md="2">
                    <p>
                      <small>
                        {{ $t("title.workingPointGPSLat") }}
                      </small>
                      <br />
                      <input v-if="point !== selectedPoint" type="text" v-model="point.address.gps_latitude"
                        class="formedituser" disabled />
                      <input v-else type="text" v-model="point.succursal.address.gps_latitude" class="formedituser"
                        @keyup="updateInputCharacters()" />
                    </p>
                  </b-col>
                </b-row>
                <b-row class="border-box">
                  <b-col class="border-box mb-3" xl="4" md="4">
                    <span class="title-address-table">
                      <strong>{{ $t("title.workingPointAddressFac") }}</strong>
                    </span>
                    <input v-if="point !== selectedPoint" type="text" v-model="point.invoice_address" class="formedituser"
                      disabled />
                    <!-- <multiselect
                      v-else
                      v-model="point.invoice_address_obj"
                      :options="invoiceAddresses"
                      class="margin-top-1rem"
                      :placeholder="$t('title.selectLabel')"
                      select-label=""
                      selected-label=""
                      deselect-label=""
                      track-by="id"
                      :custom-label="customLabelInvoiceAddresses"
                      :loading="isLoading"
                      :disabled="point !== selectedPoint"
                      @input="updateMultiSelect()"
                    /> -->
                  </b-col>
                  <b-col class="border-box mb-3" xl="4" md="4">
                    <span class="title-address-table">
                      <strong>{{ $t("title.workingPointAddressPhone") }}</strong>
                    </span>
                    <input v-if="point !== selectedPoint" type="text" v-model="point.person.land_phone" class="formedituser"
                      disabled />
                    <!-- <input v-else type="text" v-model="point.succursal.invoice_email" class="formedituser"
                      @keyup="updateInputCharacters()" /> -->
                  </b-col>
                  <b-col class="border-box mb-3" xl="4" md="4">
                    <span class="title-address-table">
                      <strong>{{ $t("title.deliveryPointContactTitle") }}</strong>
                    </span>
                    <input v-if="point !== selectedPoint" type="text" v-model="point.person.full_name"
                      class="formedituser" disabled />

                  </b-col>
                </b-row>
                <b-row v-if="editMode && point.person.person_obj" class="border-box">
                  <b-col class="border-box" xl="2" md="6">
                    <p>
                      <small>{{ $t("title.deliveryPointContactSurname") }}</small>
                      <br />
                      <input :disabled="point !== selectedPoint" type="text" v-model="point.person.person_obj.firstName"
                        class="formedituser" @keyup="updateInputCharacters()" />
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="2" md="6">
                    <p>
                      <small>{{ $t("title.deliveryPointContactName") }}</small>
                      <br />
                      <input :disabled="point !== selectedPoint" type="text" v-model="point.person.person_obj.lastName"
                        class="formedituser" @keyup="updateInputCharacters()" />
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="3" lg="6" md="6">
                    <p>
                      <small>{{ $t("title.workingPointPersonPosition") }}</small>
                      <br />
                      <!-- <multiselect
                        v-model="point.person.person_obj.function_obj"
                        :options="work"
                        class="margin-top-1rem"
                        :placeholder="$t('title.selectLabel')"
                        select-label=""
                        selected-label=""
                        deselect-label=""
                        track-by="id"
                        label="value"
                        :loading="isLoading"
                        :disabled="point !== selectedPoint"
                        @input="updateMultiSelect()"
                      /> -->
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="3" md="6">
                    <p>
                      <small>{{ $t("title.workingPointContactEmailLabel") }}</small>
                      <br />
                      <input :disabled="point !== selectedPoint" type="text" v-model="point.person.person_obj.email1"
                        class="formedituser" @keyup="updateInputCharacters()" />
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="2" md="6">
                    <p>
                      <small>{{ $t("title.workingPointContactMobileLabel") }}</small>
                      <br />
                      <input :disabled="point !== selectedPoint" type="text" v-model="point.person.person_obj.mobile1"
                        class="formedituser" maxlength="14" minlength="10" @keyup="updateInputCharacters()" />
                    </p>
                  </b-col>
                </b-row>
                <!-- <br clear="all" />
                <b-row
                  class="border-box"
                  v-if="
                    point !== selectedPoint &&
                      affiliate &&
                      affiliate.role !== 'FAC' &&
                      !affiliate.state_owned &&
                      affiliate.user_config &&
                      affiliate.user_config.roles &&
                      affiliate.user_config.roles.includes('EDITOR')
                  "
                >
                  <b-col class="border-box" md="12">
                    <p>
                      <a
                        class="notification-action custom-notification-action"
                        @click="editAddressPoint(index)"
                        >{{ $t("title.editButtonLabel") }}</a
                      >
                    </p>
                  </b-col>
                </b-row> -->
                <!-- <b-row
                  class="border-box"
                  v-if="
                    point === selectedPoint &&
                      affiliate &&
                      affiliate.role !== 'FAC' &&
                      !affiliate.state_owned &&
                      affiliate.user_config &&
                      affiliate.user_config.roles &&
                      affiliate.user_config.roles.includes('EDITOR')
                  "
                >
                  <b-col class="border-box" cols="6" md="6">
                    <p>
                      <a
                        class="notification-action"
                        @click="submitAddressPoint()"
                        >{{ $t("title.saveChangesLabel") }}</a
                      >
                    </p>
                  </b-col>
                  <b-col class="border-box" cols="6" md="6">
                    <p class="line-height-43 text-right">
                      <a
                        class="resetButton"
                        @click="resetAddressPoint(point)"
                        >{{ $t("title.cancelChangesLabel") }}</a
                      >
                    </p>
                  </b-col>
                </b-row> -->
              </form>
            </b-container>
          </div>
        </div>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_info', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.sendAddressError">
          <p>{{ $t("title.errorSentAddressPoint_" + this.sendAddressError.code) }}</p>
        </div>
        <div v-else>
          <p>{{ $t("title.sentAddressPointSuccess") }}</p>
        </div>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('title.loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import httpServiceUser from "@/http/http-user.js";
import httpServiceCommon from "@/http/http-common.js";
import Cookies from "js-cookie";
import { BContainer, BRow, BCol } from "bootstrap-vue-3";

export default {
  props: {
    affiliate: Object,
    msg: String
  },
  components: {
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol,
  },
  data() {
    return {
      selectedProduct: null,
      addressPoints: [],
      window: {
        width: 0,
        height: 0
      },
      workingPointAddress: [],
      selectedWorkingPointAddress: "",
      noAddressPoints: false,
      isLoading: false,
      selectedPerson: "",
      editMode: false,
      formData: null,
      selectedPoint: null,
      allProducts: false,
      map: new Map(),
      county: [],
      locality: [],
      work: [],
      sendAddressError: null,
      invoiceAddresses: [],
      contactPersons: [],
      detailsMobile: false,
      errorWppArrayToSubmit: false
    };
  },
  methods: {
    selectProduct(index) {
      this.selectedProduct = this.affiliate.products[index];
      this.getAddressPoints(this.selectedProduct.brand);
    },
    isSelectedProduct(product) {
      return product + "" === this.selectedProduct;
    },
    selectProductDetailsMobile(index) {
      this.productSelectedMobile = this.selectedPoint.productsAffiliateEdit[
        index
      ];
      this.detailsMobile = !this.detailsMobile;
    },
    selectedProductMobile(event) {
      this.selectedProduct = this.affiliate.products[event.target.value];
      this.getAddressPoints(this.selectedProduct.brand);
    },
    subsidiarySelected(event) {
      // this.filters.subsidiary = event.target.value;
      // this.getCardTransaction();
    },
    isSelectedSubsidiary(subsidiary) {
      return false;// subsidiary === this.filters.subsidiary;
    },
    getAddressPoints(brand = null) {
      this.isLoading = true;
      this.workingPointAddress = [];
      var params =
        "?type=affiliate" +
        "&environment=card" + // TODO de ignoral environment pe adrese de livrare
        "&per_page=10000&page=1";

      if (brand) {
        params += "&brand=" + brand;
      }

      httpServiceUser.userService
        .getAddressPoints(
          this.affiliate.company_id,
          params,
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200) {
            this.addressPoints = response.data;
            this.isLoading = false;
            this.addressPoints.forEach(x => {
              x.person.full_name =
                x.person.first_name + " " + x.person.last_name;
              this.workingPointAddress.push({
                codcom: x.codcom,
                address: x.address.street_name + ", " + x.address.locality,
                id: x.address.id
              });
            });
            if (this.addressPoints.length === 0) {
              this.noAddressPoints = true;
            }
            this.showModalPopup("modal_popup_loading", false);
          } else if (response.status == 400 || response.status == 403) {
            this.showModalPopup("modal_popup_loading", false);
            return null;
          } else {
            this.showModalPopup("modal_popup_loading", false);
            return null;
          }
        })
        .catch(error => {
          this.showModalPopup("modal_popup_loading", false);
          return null;
        });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    customLabel({ codcom, address }) {
      return `${codcom} – ${address}`;
    },
    customLabelInvoiceAddresses({ street_name, locality }) {
      return `${street_name}  ${locality}`;
    },
    addNewWorkingPoint() {
      this.selectedWorkingPointAddress = null;
      this.addressPoints.unshift({
        address: {
          county: "",
          county_id: null,
          gps_latitude: "",
          gps_longitude: "",
          id: null,
          locality: "",
          locality_id: null,
          postal_code: "",
          street_name: ""
        },
        codcom: null,
        invoice_address: "",
        invoice_address_id: null,
        invoice_email: "",
        name: "",
        person: {
          email: "",
          first_name: "",
          full_name: "",
          functionId: null,
          id: null,
          last_name: "",
          mobile_phone: ""
        },
        products: [],
        productsAffiliate: [],
        new: true,
        succursal: {
          address: {
            county: "",
            county_id: null,
            gps_latitude: "",
            gps_longitude: "",
            id: null,
            locality: "",
            locality_id: null,
            postal_code: "",
            street_name: ""
          },
          invoice_email: ""
        }
      });
      this.editAddressPoint(0);
    },
    editAddressPoint(index) {
      this.editMode = true;
      this.selectedPoint = this.filterWorkingPoints[index];
      this.selectedPoint.succursal = {
        address: {
          street_name: this.selectedPoint.address.street_name,
          locality_id: this.selectedPoint.address.locality_obj
            ? this.selectedPoint.address.locality_obj.id
            : null,
          locality: null,
          county_id: this.selectedPoint.address.county_obj
            ? this.selectedPoint.address.county_obj.id
            : null,
          county: null,
          postal_code: null,
          gps_latitude: this.selectedPoint.address.gps_latitude,
          gps_longitude: this.selectedPoint.address.gps_longitude
        },
        invoice_email: this.selectedPoint.invoice_email
      };

      this.county = this.map.get("county");
      if (this.selectedPoint.address.id) {
        this.selectedPoint.address.county_obj = this.county.find(
          x => x.id === this.selectedPoint.address.county_id
        );
        this.getNomenclatures(
          "locality",
          this.selectedPoint.address.county_obj.id,
          null,
          null,
          index
        );
        this.selectedPoint.invoice_address_obj = this.invoiceAddresses.find(
          x => x.id === this.selectedPoint.invoice_address_id
        );
        this.selectedPoint.person.person_obj = this.contactPersons.find(
          x => x.id === this.selectedPoint.person.id
        );
      }

      // this.selectedPerson = {...this.selectedPoint.person.person_obj};

    },
    resetAddressPoint(addressPoint) {
      this.formData = addressPoint;
      this.selectedPoint = null;
      this.editMode = false;
    },
    getNomenclatures(
      item,
      countyId = null,
      shopProductId = null,
      shopProfileId = null,
      index = null
    ) {
      this.isLoading = true;

      let url = "type=" + item + "&per_page=200&page=1";

      if (item === "locality") {
        url += "&county=" + countyId;
      } else if (item === "shop_type") {
        url +=
          "&shop_product=" + shopProductId + "&shop_profile=" + shopProfileId;
      }

      httpServiceCommon.companyService
        .getNomenclature(url, Cookies.get("token"))
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            this.map.set(item, response.data);
            this.isLoading = false;
            if (item === "locality") {
              this.locality = this.map.get("locality");
              this.selectedPoint.address.locality_obj = this.locality.find(
                x => x.id === this.selectedPoint.address.locality_id
              );
            }
          } else {
            // this.companyDataError = { description: response.status };
          }
        })
        .catch(error => {
          if (error.response) {
            this.isLoading = true;
            // this.companyDataError = { description: error.response.status };
          } else {
            // this.companyDataError = { description: "undefined" };
          }
        });
    },
    selectLocality(countyId) {
      this.getNomenclatures("locality", countyId);
    },
    closeAndOpenPopup(type) {
      if (type === "submitAddressPoint") {
        this.showModalPopup("modal_popup_loading", false);
        this.showModalPopup("modal_popup_info", true);
      }
    },
    onlyAlphabetCharacters(variable) {
      if (/^[a-zA-Z]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[0-9&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g, "");
      }
    },
    onlyAlphaNumericCharacters(variable) {
      if (/^[a-zA-Z0-9]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[&\/\\|`#\[\]\-,+=()@$~%'":*!?<>{}]/g, "");
      }
    },
    onlyAlphaNumericCharactersMail(variable) {
      if (/^[a-zA-Z0-9@.]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[&\/\\|`#\[\]\-,+=()$~%'":*!?<>{}]/g, "");
      }
    },
    onlyNumericCharacters(variable) {
      if (/^[0-9.]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(
          /[a-zA-Z&\/\\|`#@\[\]\-,+=()$~%'":*!?<>{}]/g,
          ""
        );
      }
    },
    updateInputCharacters() {
      if (this.selectedPoint.succursal.address.street_name) {
        this.selectedPoint.succursal.address.street_name = this.onlyAlphaNumericCharacters(
          this.selectedPoint.succursal.address.street_name
        )
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "");
      }
      // if (this.selectedPoint.succursal.invoice_email) {
      //   this.selectedPoint.succursal.invoice_email = this.onlyAlphaNumericCharactersMail(
      //     this.selectedPoint.succursal.invoice_email
      //   )
      //     .normalize("NFD")
      //     .replace(/\p{Diacritic}/gu, "")
      //     .trim("");
      // }
      if (this.selectedPoint.succursal.address.gps_latitude) {
        this.selectedPoint.succursal.address.gps_latitude = this.onlyNumericCharacters(
          this.selectedPoint.succursal.address.gps_latitude
        ).trim("");
      }
      if (this.selectedPoint.succursal.address.gps_longitude) {
        this.selectedPoint.succursal.address.gps_longitude = this.onlyNumericCharacters(
          this.selectedPoint.succursal.address.gps_longitude
        ).trim("");
      }
      if (this.selectedPoint.person.person_obj.firstName) {
        this.selectedPoint.person.person_obj.firstName = this.onlyAlphabetCharacters(
          this.selectedPoint.person.person_obj.firstName
        )
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .trim("");
      }
      if (this.selectedPoint.person.person_obj.lastName) {
        this.selectedPoint.person.person_obj.lastName = this.onlyAlphabetCharacters(
          this.selectedPoint.person.person_obj.lastName
        )
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .trim("");
      }
      // if (this.selectedPoint.person.person_obj.email1) {
      //   this.selectedPoint.person.person_obj.email1 = this.onlyAlphaNumericCharactersMail(
      //     this.selectedPoint.person.person_obj.email1
      //   )
      //     .normalize("NFD")
      //     .replace(/\p{Diacritic}/gu, "")
      //     .trim("");
      // }
      if (this.selectedPoint.person.person_obj.mobile1) {
        this.selectedPoint.person.person_obj.mobile1 = this.onlyNumericCharacters(
          this.selectedPoint.person.person_obj.mobile1
        ).trim("");
      }
      this.$forceUpdate();
    },
  },
  created() {
    this.selectProduct(0);

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.token = Cookies.get("token");
    // this.getNomenclatures("county");
  },
  computed: {
    filterWorkingPoints: function () {
      if (
        this.selectedWorkingPointAddress &&
        this.selectedWorkingPointAddress !== ""
      ) {
        const result = this.addressPoints.filter(
          x => x.address.id === this.selectedWorkingPointAddress.id
        );
        return result;
      } else {
        return this.addressPoints;
      }
    }
  },
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.margin-paragraph {
  margin: 1rem 0;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.notification-action {
  display: inline-block;
  width: auto;
  padding: 10px;
}
</style>
