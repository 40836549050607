<template>
	<div>
		<table
			cellpadding="10"
			cellspacing="0"
			width="95%"
			class="hover-table invoices-table"
			align="center"
		>
			<thead>
				<tr>
					<td valign="middle">
						<strong>{{ $t("title.transactionIdLabel") }}</strong>
            <img
							src="@/assets/images/arrow-down-fill.svg"
							@click="sortBy('prdtrid')"
							:style="
								sortByField === 'prdtrid' && reverse && 'transform: rotate(180deg)'
							"
						/>
					</td>
					<td valign="middle">
						<strong>{{ $t("title.nameStoreLabel") }}</strong>
            <img
							src="@/assets/images/arrow-down-fill.svg"
							@click="sortBy('nameStore')"
							:style="
								sortByField === 'nameStore' && reverse && 'transform: rotate(180deg)'
							"
						/>
					</td>
					<td class="priority-1" valign="middle">
						<strong>{{ $t("title.localityLabel") }}</strong>
						<img
							src="@/assets/images/arrow-down-fill.svg"
							@click="sortBy('locality')"
							:style="
								sortByField === 'locality' && reverse && 'transform: rotate(180deg)'
							"
						/>
					</td>
					<td class="priority-2" valign="middle">
						<strong>{{ $t("title.cardNumberLabel") }}</strong>
						<img
							src="@/assets/images/arrow-down-fill.svg"
							@click="sortBy('cardNumber')"
							:style="
								sortByField === 'cardNumber' &&
									reverse &&
									'transform: rotate(180deg)'
							"
						/>
					</td>
					<td class="priority-3" valign="middle">
						<strong>{{ $t("title.dateLabel") }}</strong>
						<img
							src="@/assets/images/arrow-down-fill.svg"
							@click="sortBy('date')"
							:style="
								sortByField === 'date' &&
									reverse &&
									'transform: rotate(180deg)'
							"
						/>
					</td>
					<!-- <td class="priority-4" valign="middle">
						<strong>{{ $t("title.typeLabel") }}</strong>
            <img
							src="@/assets/images/arrow-down-fill.svg"
							@click="sortBy('type')"
							:style="
								sortByField === 'type' &&
									reverse &&
									'transform: rotate(180deg)'
							"
						/>
					</td> -->
					<td class="priority-5" valign="middle">
            <strong>{{ $t("title.valueLabel") }}</strong>
            <img
							src="@/assets/images/arrow-down-fill.svg"
							@click="sortBy('value')"
							:style="
								sortByField === 'value' &&
									reverse &&
									'transform: rotate(180deg)'
							"
						/>
          </td>
				</tr>
			</thead>
			<tbody>
				<template v-for="(transaction, index) in companyTransactions" :key="index">
					<tr :id="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'" >
						<td valign="middle">
							<a
								class="detailsOrderMobile priority-show-5 display-inline-block"
								@click="selectTransactionMobile(index)"
							>
								<font-awesome-icon
									icon="minus-circle"
									v-if="detailsMobile && transaction === transactionSelectedMobile"
								/>
								<font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
							</a>
							<strong>{{ transaction.transaction_id }}</strong>
						</td>
						<td valign="middle">
              {{ transaction.affiliate.name.trim() }}
            </td>
						<td valign="middle" class="priority-1">
							{{ transaction.affiliate.address.locality.trim() }}
						</td>
						<td valign="middle" class="priority-2">
							{{ transaction.pan.trim() }}
						</td>
						<td valign="middle" class="priority-3">
              {{ displayDate(transaction.date) }}
						</td>
						<!-- <td valign="middle" class="priority-4">
							{{ transaction.transaction_type }}
						</td> -->
						<td valign="middle" class="priority-5">
              {{ transaction.amount }} {{ $t("title.currency") }}
            </td>
					</tr>
					<tr :id="index + companyTransactions.length"
						class="child"
						:class="index % 2 === 0 && 'gri-cell'"
						v-show="detailsMobile && transaction === transactionSelectedMobile"
					>
						<td colspan="12">
							<p class="priority-show-1">
								<strong>{{ $t("title.localityLabel") }} : &nbsp;&nbsp;</strong>
								<span>{{ transaction.affiliate.address.locality.trim() }}</span>
							</p>
							<p class="priority-show-2">
								<strong>{{ $t("title.cardNumberLabel") }} : &nbsp;&nbsp;</strong>
								<span>{{ transaction.pan.trim() }}</span>
							</p>
							<p class="priority-show-3">
								<strong>{{ $t("title.dateLabel") }} : &nbsp;&nbsp;</strong>
								<span>{{ displayDate(transaction.date) }}</span>
							</p>
							<!-- <p class="priority-show-4">
								<strong>{{ $t("title.typeLabel") }} : &nbsp;&nbsp;</strong>
								<span>{{ transaction.transaction_type }}</span>
							</p> -->
              <p class="priority-show-5">
								<strong>{{ $t("title.valueLabel") }} : &nbsp;&nbsp;</strong>
								<span>{{ transaction.amount }} {{ $t("title.currency") }}</span>
							</p>
						</td>
					</tr>
				</template>
			</tbody>
		</table>  
	</div>
</template>
<script>
import moment from "moment";

export default {
	name: "CardTransactionTable",
	props: {
		companyTransactions: Array,
		company: Object,
		msg: String,
	},
	data() {
		return {
			detailsMobile: false,
			transactionSelectedMobile: null,
			sortByField: "date",
			reverse: false,
		};
	},
	methods: {
		displayDate(date) {
			return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format("DD/MM/YYYY");
		},
		selectTransactionMobile(index) {
			this.transactionSelectedMobile = this.companyInvoices[index];
			this.detailsMobile = !this.detailsMobile;
		},
		sortBy(fieldName) {
			if (this.sortByField !== fieldName) {
				this.reverse = false;
			}
			this.sortByField = fieldName;
			this.reverse = !this.reverse;
			this.$emit("change", fieldName, this.reverse ? "asc" : "desc");
		},
	},
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
